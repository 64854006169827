import { ProductsCarouselProps } from 'src/components/views/ProductsCarousel/ProductsCarousel';
import useLastViewProductEansFromCookies from 'src/hooks/useLastViewProductEansFromCookies/useLastViewProductEansFromCookies';
import useProductsCarouselHandlers from 'src/hooks/useProductsCarouselHandlers/useProductsCarouselHandlers';
import { useSwrProductsByEans } from 'src/hooks/useSwrProductsByEans/useSwrProductsByEans';
import { selectCartItems, selectStoreId } from 'src/redux/cart/general/cartSelectors';
import selectDeliveryType from 'src/redux/cart/general/selectDeliveryType';
import { useAppSelector } from 'src/redux/hooks';
import useFavorites from 'src/services/favorites/useFavorites';
import useLocalization from 'src/services/localization/useLocalization';
import { addToCartButtonsEnum } from 'src/utils/marketing/enhancedEcommerce';
import mapViewProducts from 'src/utils/product/mapViewProducts';

const place = addToCartButtonsEnum.LAST_VIEW_PRODUCTS_SLIDER;

export default function useLastViewProductsProps(slideCount: number, ean?: string) {
  const localize = useLocalization();
  const { getProductIsFavorite } = useFavorites();

  const { eans } = useLastViewProductEansFromCookies();

  const cartItems = useAppSelector(selectCartItems);
  const language = useAppSelector((s) => s.app.language);
  const itemsCanBeAdded = useAppSelector((s) => !!s.app.cartType);
  const storeId = useAppSelector(selectStoreId);
  const deliveryType = useAppSelector(selectDeliveryType);

  // remove the currently open product from the list
  const filteredEans = eans.filter(e => e !== ean);

  const { lastViewProducts, lastViewProductsIsFetching, error } = useSwrProductsByEans({
    storeId,
    deliveryType,
    eansList: filteredEans,
  });

  const {
    handleAddToCart,
    handleAmountChange,
    handleFavoriteClick,
    handleProductTileClick,
  } = useProductsCarouselHandlers(place);

  const titleText = localize('recently_viewed');
  const sliderItems = mapViewProducts(lastViewProducts, getProductIsFavorite, cartItems);

  const placeholderShouldBeDisplayed = lastViewProducts.length >= slideCount && lastViewProductsIsFetching;
  const sliderShouldNotDisplayed = error || (!lastViewProductsIsFetching && lastViewProducts.length < slideCount);

  const carouselProps: ProductsCarouselProps = {
    title: titleText,
    items: sliderItems,
    productPlace: place,
    language: language,
    itemsCanBeAdded: itemsCanBeAdded,
    slideCount,
    onProductTileClick: handleProductTileClick,
    onAddToCart: handleAddToCart,
    onAmountChange: handleAmountChange,
    onFavoriteClick: handleFavoriteClick,
  };

  return {
    carouselProps,
    sliderShouldNotDisplayed,
    placeholderShouldBeDisplayed,
  };
}
